import { useContext, useState, useMemo, useEffect } from 'react';
import { ProviderContext } from '../../App';


import { Player, Controls } from '@lottiefiles/react-lottie-player';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Chart, Doughnut, Pie  } from 'react-chartjs-2';
import 'chart.js/auto'; // ADD THIS

import NotifCard from '../../components/Cards/NotifCard';
import Ring from "../../components/ring"

import SearchCard from '../../components/Cards/SearchCard';
import { formatNumberWithCommasAndDots } from '../../components/functions';

const today = new Date()
const month = ('' + today + '').split(' ')[1]
const year = ('' + today + '').split(' ')[3]


Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

Date.prototype.substractDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() - days);
    return date;
}


 /* eslint-disable padded-blocks */
let generateID = (len,arr) => {
    let ans =''
    for(let i = len; i > 0; i--){
        ans += arr[Math.floor(Math.random() * arr.length)]
    }
    return ans
}



let Home = (props) => {
    const loaded = props.Loaded
    const {profile,env} = useContext(ProviderContext)
    const [open, isOpen] = useState(true)
    const requests = props.RequestData
    const fiscal = props.Fiscal
    const isSelected = props.isSelected
    const acceptedRequests = requests.filter(obj => obj.Complete);
    const acceptedRequests_Recent = acceptedRequests.slice(0, 3);

    const [downloadQuote, setDownloadQuote] = useState(null)
    const [downloadFormat, setDownloaFormat] = useState(null)

    //console.log('completed data ===> ',acceptedRequests_Recent)

    const deniedRequests = requests.filter(obj => !obj.Complete);
    const deniedRequests_Recent = deniedRequests.slice(0, 3);
    
    
    const [currRequest, isCurrRequest] = useState(null)
    const [currValue, isCurrValue] = useState(0)

    //console.log('request data ===> ',requests)

    const [highlight, isHighlight] = useState(false)
    // purchase request details ======================================================>
    const [CapexID, isCapexID] = useState(generateID(8,'abcde0123456789ABCDEFG'))
    const [quantity, setQuantity] = useState('');
    const [departmentValue, isDepartmentValue] = useState('none')
    const [departments, isDepartments] = useState([])
    const [assetcategory, isAssetCategory] = useState('none')
    const [jobcodeValue, isJobCodeValue] = useState('none')
    const [jobcodesearch, isJobCodeSearch] = useState([])
    
   
    const [partsearch, isPartSearch] = useState(false)
    const [partValue, isPartValue] = useState('')

    const [itemdescsearch, isItemDescSearch] = useState(false)
    const [itemdescValue, isItemDescValue] = useState('')

    const [startDate, setStartDate] = useState(new Date())
    const [startDateQuote, setStartDateQuote] = useState(today)
    const [startDateNeed, setStartDateNeeded] = useState(new Date())

    const [justificationOfPurchase, isJustificationOfPurchase] = useState('')
    const [shiptoaddress, isShipToAddress] = useState('')
    const [shipfromaddress, isShipFromAddress] = useState('')
    const [shiptocity, isShipToCity] = useState('')
    const [shipfromcity, isShipFromCity] = useState('N/A')

    const [customitem, isCustomItem] = useState(false)
    const [hasprojectcode, setHasProjectCode] = useState(true)
    const [newvendor, hasNewVendor] = useState(false)

    // cost details====================================================================>
    const [vendorsearch, isVendorSearch] = useState(false)
    const [vendorValue, isVendorValue] = useState([])
    const [budgeted, isBudgeted] = useState('true')

    const [unbudgeted, isUnbudgeted] = useState('N/A')
    const [estimatedValue, isEstimatedValue] = useState('')
    const [budgetcodeArr, isBudgetCodeArr] = useState([])
    const [budgetcodeValue, isBudgetCodeValue] = useState('none')
    const [quoteValue, isQuoteValue] = useState('')
    const [budgetamountValue, isBudgetAmountValue] = useState('')
    const [budgetcodesearch, isBudgetCodeSearch] = useState(false)

    // contact information ================================================================================>
    const [originator, isOriginator] = useState(profile?.name)
    const [phone, isPhone] = useState('')


    //======================================================================================>
    const [search,isSearch] = useState([])
    const [progress, isProgress] = useState(0)


   
    const [firstapprover,isFirstApprover] = useState('rhonda.borbiliac@heathus.com')
    


    const [animate, isAnimate] = useState(false)

    
    const [confirmRequest, isConfirmRequest] = useState(false)

    const [validate1, isValidate1] = useState(false)
    const [validate2, isValidate2] = useState(false)
    const [validate3, isValidate3] = useState(false)
    const [validate4, isValidate4] = useState(true)
    const [check, isCheck] = useState(false)
    const [requestongoing, isRequestOngoing] = useState(false)

    const testArr = [validate1,validate2,validate3,validate4]
    const [currArr, isCurrArr] = useState(0)
    const [base64String, setBase64String] = useState('');

    const [confirmRefresh, setConfirmRefresh] = useState(true);

    // =====================================
    const [fixDept, isFixDept] = useState(0)

    const titleMap = {
        0: 'Purchase Request Details',
        25: 'Cost Details',
        50: 'Contact Information',
        75: 'Acknowledge Timeline',
        100: 'Acknowledge Request',
    }

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (confirmRefresh) {
                const confirmationMessage =
                  'Are you sure you want to refresh? Any unsaved changes will be lost.';
                e.returnValue = confirmationMessage;
                return confirmationMessage;
            }
          
        };
    
        window.addEventListener('beforeunload', requestongoing? handleBeforeUnload : null);
    
        return () => {
          window.removeEventListener('beforeunload', requestongoing? handleBeforeUnload : null);
        };
    }, [confirmRefresh]);

    const handleRefreshConfirmation = () => {
        setConfirmRefresh(false);
        // Optionally, you can proceed with the refresh here
        window.location.reload();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
    
        reader.onload = (event) => {
          const base64 = event.target.result;
          setBase64String(base64);
          //console.log('base 64 ===> ',base64.length, base64)
        };
    
        reader.onerror = (error) => {
          console.error('Error reading file:', error);
        };
    
        reader.readAsDataURL(file);
    };

    const resetForm = () => {
        isCapexID(generateID(8,'abcde0123456789ABCDEFG'))
        isProgress(0)
        setQuantity('');
        isDepartmentValue('none')
        isDepartments([])
        isAssetCategory('none')
        isJobCodeValue('none')
        isJobCodeSearch([])
        
        isRequestOngoing(false)
    
        isPartSearch(false)
        isPartValue('')

        isItemDescSearch(false)
        isItemDescValue('')

        setStartDate(new Date())
        setStartDateQuote(new Date())
        setStartDateNeeded(new Date())

        isJustificationOfPurchase('')
        isShipToAddress('')
        isShipFromAddress('')
        isShipToCity('')
        isShipFromCity('')

        // cost details====================================================================>
        isVendorSearch(false)
        isVendorValue([])
        isBudgeted('true')

        isUnbudgeted('')
        isEstimatedValue('')
        isBudgetCodeArr([])
        isBudgetCodeValue('')
        isQuoteValue('')
        isBudgetAmountValue('')
        isBudgetCodeSearch(false)
        isPhone('')
        isCustomItem(false)

        isAnimate(false)

    
        isConfirmRequest(false)
    
        isValidate1(false)
        isValidate2(false)
        isValidate3(false)
        isValidate4(true)
        isCheck(false)
    
        isCurrArr(0)
        isHighlight(false)

        /* eslint-disable */
        $('.form-select').val('none')
        // $('.form-control').text('')
        
        
        $('#custom-carousel').carousel(0);
    }

    const searchBy = async (type,search) => {

        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
          
        await fetch(`${env}/api/${type}?tosearch=${search}`, requestOptions)
        .then(response => response.text())
        .then(result => {
            //console.log(JSON.parse(result))
            isSearch(JSON.parse(result))
        })
        .catch(error => {
            console.log('error', error)
            // isSearch(['An error occured.'])
        });
    };

    const getDepartments = async () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
          
        await fetch(`${env}/api/getdepartments`, requestOptions)
        .then(response => response.text())
        .then(result => {
            //console.log('all departments ==> ', JSON.parse(result))

            isDepartments(JSON.parse(result))
            
        })
        .catch(error => {
            console.log('error getting departments', error)
            isFixDept(fixDept + 1)
            // isDepartments(['An error occured.'])
        });
    } 

    const getJobCodes = async (search) => {

        //console.log('get job code of ==> ',search)
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
          
        await fetch(`${env}/api/getjobcodes?tosearch=${search}`, requestOptions)
        .then(response => response.text())
        .then(result => {
            //console.log('all job codes ==> ', JSON.parse(result))
            isJobCodeSearch(JSON.parse(result))
        })
        .catch(error => {
            console.log('error', error)
            // isJobCode(['An error occured.'])
        });
    } 

    const getBudgetCodes = async (search) => {
        console.log('look for budget code with department ===> ', search)
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
          
        await fetch(`${env}/api/getbudgetcodes?tosearch=${search}`, requestOptions)
        .then(response => response.text())
        .then(result => {
            //console.log('all job codes ==> ', JSON.parse(result))
            // isSearch(JSON.parse(result))
            isBudgetCodeArr(JSON.parse(result))
            isBudgetAmountValue('0')
        })
        .catch(error => {
            console.log('error', error)
            // isJobCode(['An error occured.'])
        });
    } 

    const getVendors = async (search) => {
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
          
        await fetch(`${env}/api/getvendors?tosearch=${search}`, requestOptions)
        .then(response => response.text())
        .then(result => {
            //console.log('all vendors ==> ', JSON.parse(result))
            isSearch(JSON.parse(result))
        })
        .catch(error => {
            console.log('error', error)
            isSearch(['An error occured.'])
        });
    } 

    const getFirstApprover = async (department) => {
       
     
        //console.log('approver to look for ===> ',department)
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
          
        await fetch(`${env}/api/capex/getFirstApprover?department=${department}`, requestOptions)
        .then(response => response.text())
        .then(result => {
            //console.log('first approver ==> ', JSON.parse(result))
            const res = JSON.parse(result)

            if(res.length > 0){
                isFirstApprover(res[0].ID)
            }
        })
        .catch(error => {
            console.log('error', error)
            isSearch(['An error occured.'])
        });
    } 

    const approvalRequired = () => {
        //console.log('the estimated value is ===> ',estimatedValue)
        if (estimatedValue <= 5000) return 2
        else if (estimatedValue > 5000 && estimatedValue <= 249999) return 3
        else return 4
    }
    
    let submitRequest = async () => {
        console.log('current budgeted val ==> ',budgeted)
        let data = {
            ID: CapexID,
            AssetCategory: assetcategory,
            Quantity: quantity,
            PartNumber: partValue,
            JustificationOfPurchase: justificationOfPurchase,
            ItemDescription: itemdescValue,
            ShipToAddress: shiptoaddress,
            Location: shipfromaddress,
            AddressTo: shiptocity,
            AddressFrom: shipfromcity,
            Vendor: vendorValue,
            Quote: quoteValue,
            QuoteValue: base64String,
            QuoteDate: startDateQuote,
            Department: departmentValue,
            Budgeted: budgeted,
            Unbudgeted: unbudgeted,
            EstimatedValue: estimatedValue,
            ProjectCode: jobcodeValue,
            BudgetCode: budgetcodeValue,
            BudgetAmount: budgetamountValue,
            OriginatorName: originator,
            OriginatorEmail: profile?.username,
            Phone: phone,
            DateCost: today,
            DateNeeded: startDateNeed,
            DateRequest: today,
            ApprovalDateMonth: null,
            ApprovalFiscalYear: null,
            ApprovalRequired: approvalRequired(),
            Approval1: 'pending',
            Approval1ByName: null,
            Approval1Date: null,
            Approval2: approvalRequired() >= 2? "pending" : null,
            Approval2Date: null,
            Approval3: approvalRequired() >= 3? "pending" : null,
            Approval3Date: null,
            Approval4: approvalRequired() == 4? "pending" : null,
            Approval4Date: null,
            Approval2ByName: null,
            Approval3ByName: null,
            Approval4ByName: null,
            Approval1ByEmail: null,
            Approval2ByEmail: null,
            Approval3ByEmail: null,
            Approved4ByEmail: null,
            Complete: false,
            FiscalYear:fiscal,
            Status: 'pending',
        }
        let stringifiedData = JSON.stringify(data)

        // console.log('data to submit ==> ',data)
        // console.log('to submit estimated value ==> ', estimatedValue)
        // console.log('to submit ==> ', approvalRequired())
        
        var requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: stringifiedData
        };
          
        await fetch(`${env}/api/capex/submitRequest`, requestOptions)
        .then(response => response.text())
        .then(result => {
            //console.log(result)
            
            setTimeout(() => {
                resetForm()
            }, 5500);
        })
        .catch(error => {
            console.log('error', error)
        });

        /* eslint-disable */
    }

   

    let sendEmail = async (subject, body, address, displayName) => {
        
        // console.log('email ===> ', subject, body, address, displayName)
        
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
          
        await fetch(`${env}/api/capex/sendEmail?subject=${subject}&body=${body}&address=${address}&displayName=${displayName}`, requestOptions)
        .then(response => response.text())
        .then(result => {
           console.log('email sent !')
        })
        .catch(error => {
            console.log('error', error)
        });

    }

    let fixPrice = (x) => {
        // console.log('number to fix ==> ',parseInt(x))
        const numericPart = x.replace(/\D/g, '');
        if(!parseInt(numericPart) || parseInt(numericPart) < 1){
            return 1
        }
    
        else if(parseInt(numericPart) > 100) return 100
        else return numericPart
    }

    function dataURItoFile(dataURI) {
        // Extract the Base64 content
        if(dataURI && dataURI.length > 15){
            const base64Content = dataURI.split(',')[1];
            const fileType = dataURI.split('/')[1].split(';')[0]
          
            // Decode Base64 string into binary data
            const binaryString = atob(base64Content);
          
            // Create a typed array from binary string
            const byteArray = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
              byteArray[i] = binaryString.charCodeAt(i);
            }
          
            // Create Blob from the typed array with appropriate MIME type
            const blob = new Blob([byteArray], { type: dataURI.split(';')[0] });
          
            // Optionally, create a URL for the Blob
            const fileURL = URL.createObjectURL(blob);
            
            let getFileTypeFromDataURI = (type) => {
            
                switch (type) {
                    case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
                        return 'docx';
                    case 'pdf':
                        return 'pdf';
                    case 'png':
                        return 'png';
                    case 'jpeg':
                        return 'jpg';
                    case 'jpg':
                        return 'jpg';
                    default:
                        throw new Error('Unsupported file type');
                }
            }
          
            console.log('converted blob file ===> ',fileURL)
          
            setDownloadQuote(fileURL)
            setDownloaFormat(getFileTypeFromDataURI(fileType))
            // return blob; // or return the Blob object if needed
        }
     
    }

    let searchCard = search.map((data) => {
        return (
            <SearchCard
                onClick={() => {
                    isPartValue(data.PartNum)
                    isItemDescValue(data.Partdescription)
                    isPartSearch(false)
                    isItemDescSearch(false)
                    // validateFirstSlide()
                }}
                PartSearch = {partsearch}
                PartNum = {data.PartNum}
                PartDescription = {data.Partdescription}
            />
        )
    })

    let searchVendorCard = search.map((data) => {
        return (
            <p onClick={() => {
                isVendorValue(data.Name)
                isVendorSearch(false)
            }}> {data.Name}</p>
        )
    })

    let departmentsCard = departments.map((data) => {
        if(data.DepartmentDescription.length > 3) {
            return(
                <option 
                    value={data.LastTwoDigits + ' / ' + data.DepartmentDescription}
                    onClick={() => {
                        //console.log('hi')
                    }}
                >
                    {data.LastTwoDigits}  / {data.DepartmentDescription} 
                </option>
            )
        }
    })

    let budgetcodeCard = budgetcodeArr.map((data) => {
        return(
            <option 
                value={data.AssetBudgetCode}
                budget={parseInt(data.Total) - parseInt(data.Spent)}
                // onClick={() => {
                //     console.log('hi')
                //     isBudgetCodeValue(data.AssetBudgetCode)
                //     isBudgetAmountValue(parseInt(data.Total) - parseInt(data.Spent))
                // }}
            >
                {data.AssetBudgetCode} / {data.AssetName}
            </option>
        )
    })

    let pCodeCard = jobcodesearch.map((data) => {
        return(
            <option
                value={data.ProjectCode}
            >
                {data.Project}
            </option>
        )
    })

    let bCodeCard = search.map((data) => {
        return(
            <p 
                onClick={() => {
                    isBudgetCodeValue(data.AssetName)
                    isBudgetAmountValue(data.Total)
                    isBudgetCodeSearch(false)
                }}
            >
                <span style={{color:'#a7a3a3'}}>{data.AssetBudgetCode} / </span> {data.AssetName}
            </p>
        )
    })

    let renderCardsOngoing = deniedRequests_Recent?.map((data) => {
        
        let maxScore = 1
        let currScore = 0

        let getScore = () => {
            if(data.Approval4) maxScore += 1
            if(data.Approval3) maxScore += 1
            if(data.Approval2) maxScore += 1

            if(data.Approval4 && data.Approval4 == 'approved') currScore += 1
            if(data.Approval3 && data.Approval3 == 'approved') currScore += 1
            if(data.Approval2 && data.Approval2 == 'approved') currScore += 1
            if(data.Approval1 && data.Approval1 == 'approved') currScore += 1

            let res = currScore / maxScore

            if(res < 0.5) return 'clock_loader_20'
            else if(res <= 0.75) return 'clock_loader_40'
            else if(res < 1) return 'clock_loader_80'
            else return true

        }
        //console.log('res ===> ',currScore, ' <===> ', maxScore)
        return(
            <NotifCard 
                ID = {data.ID}
                Name = {data.ItemDescription? data.ItemDescription : 'Unidentified name'}
                Icon = {getScore()}
                Complete = {false}
                onClick={() => {
                    setDownloadQuote(null)
                    setDownloaFormat(null)
                    if(data?.QuoteValue?.length > 10){
                        //console.log('convert to blob')
                        dataURItoFile(data?.QuoteValue)
                    }
                    isCurrRequest(data)
                    isAnimate(true)
                }}
            />
           
        )
    
    })

    let renderCardsApproved = acceptedRequests_Recent?.map((data) => {
        
        let maxScore = 1
        let currScore = 0

        let getScore = () => {
            if(data.Approval4) maxScore += 1
            if(data.Approval3) maxScore += 1
            if(data.Approval2) maxScore += 1

            if(data.Approval4 && data.Approval4 == 'approved') currScore += 1
            if(data.Approval3 && data.Approval3 == 'approved') currScore += 1
            if(data.Approval2 && data.Approval2 == 'approved') currScore += 1
            if(data.Approval1 && data.Approval1 == 'approved') currScore += 1

            let res = currScore / maxScore

            if(res == 1) return true
        }
        return(
            data.Complete &&
            <NotifCard 
                ID = {data.ID}
                Name = {data.ItemDescription? data.ItemDescription : 'Unidentified name'}
                Icon = {data.Status == 'approved'? 'check_circle' : 'cancel'}
                Complete = {data.Status}
                onClick={() => {
                    isCurrRequest(data)
                    isAnimate(true)
                    dataURItoFile(data?.quoteValue)
                }}
            />
        )
    
    })

    let validateFirstSlide = () => {
        //console.log(partValue,itemdescValue)
        if(
            quantity?.length > 0 && partValue?.length > 0 && itemdescValue?.length > 0 && justificationOfPurchase?.length > 0 && shiptoaddress?.length > 0 && shipfromaddress?.length > 0 && shiptocity?.length > 0 && shipfromcity?.length > 0 && assetcategory !== 'none'
        ){
            console.log('condition filled to continue')
            isHighlight(false)
            return true
            
        }
        else{
            console.log('condition not met')
            isHighlight(true)
        }
    }
    let validateSecondSlide = () => {
        //console.log(vendorValue, departments !== 'none', quoteValue, budgeted, estimatedValue, jobcodeValue, budgetcodeValue)
        if(
            vendorValue?.length > 2 && departments !== 'none' && quoteValue.length > 0 && budgeted !== 'none' && estimatedValue > 0 && jobcodeValue.length > 0 && base64String.length > 15
        ){
            //console.log('condition 2 filled to continue')
            isHighlight(false)
            return true
        }
        else{ 
            //console.log('condition 2 not met')
            isHighlight(true)
        }
    }

    let validateThirdSlide = () => {
        // console.log(vendorValue, departments !== 'none', quoteValue, budgeted, estimatedValue, jobcodeValue, budgetcodeValue)
        if(
            originator?.length > 2 && phone.length > 8  
        ){
            //console.log('condition 3 filled to continue')
            isHighlight(false)
            return true
        }
        else{ 
            //console.log('condition 2 not met')
            isHighlight(true)
        }
    }

    useEffect(() => {
        getDepartments()
    },[fixDept])

    return(
        <div className='row w-100 h-100'>

            <div className="modal" id="requestCard" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" data-bs-delay='{"show":5000,"hide":5000}'>
                <div className="modal-dialog modal-fullscreen">
                    
                    <div className="row w-100 h-100" style={{height:'70%'}}>
                        <div className="col-12 center">
                            <div className={`request-card active animate__animated ${open? 'animate__zoomInUp' : 'animate__zoomOutDown'} animate__faster`} >
                                <br/>
                                <div className="w-100">
                                    <div className="blue-card">
                                        <div className="d-flex">
                                            <h5 className="mb-3" style={{width:'100%'}}>{titleMap[progress]}</h5>
                                            <h4 >{progress + '%'}</h4>
                                        </div>
                                        
                                        <div className="progress custom-progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                            <div className="progress-bar progress-bar-striped progress-bar-animated" style={{width: progress + '%'}}></div> 
                                        </div>
                                    </div>

                                    <br/>
                                    
                                    <div className="blue-card">
                                        <div className="row" >
                                        
                                            <div className="col-lg-2 col-4">
                                                <div 
                                                    className="continueBtn" 
                                                    style={{backgroundColor:'#E53935'}}
                                                    onClick={() => {
                                                        resetForm()
                                                        isOpen(false)
                                                        setTimeout(() => {
                                                            // eslint-disable-next-line
                                                            isRequestOngoing(false)
                                                            $('#requestCard').modal('hide')
                                                            isOpen(true)
                                                        }, 550);
                                                    }}
                                                >
                                                    Cancel
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-3 hide"></div>

                                            <div className="col-lg-2 col-4 ">
                                                {
                                                    progress > 0 && 
                                                    ( 
                                                        <div 
                                                            className="continueBtn" 
                                                            data-bs-target="#custom-carousel" 
                                                            data-bs-slide="prev" 
                                                            style={{backgroundColor:'#EF6C00'}}
                                                            onClick={() => {
                                                                isCurrArr(currArr - 1)
                                                                if(progress > 0){
                                                                    isProgress(progress - 25)
                                                                }

                                                                if(progress == 50){
                                                                    //console.log('we reached 75%')
                                                                    setTimeout(() => {isAnimate(true)}, 2000)
                                                                }
                                                                
                                                            }}
                                                        >
                                                            Previous
                                                        </div>
                                                    )
                                                }
                                            
                                            </div>

                                            <div className="col-lg-2 col-4">
                                                {
                                                    progress == 100?
                                                    (
                                                        <div 
                                                            className="continueBtn" 
                                                            style={{backgroundColor:'#43A047'}}
                                                            onClick={() => {
                                                                
                                                                submitRequest()
                                                                sendEmail(
                                                                    `Request with ID - ${CapexID} has been sent by ${profile.name}`, 
                                                                    `Hi, ${profile.name}, has requested item ${itemdescValue} with an estimated budget of ${estimatedValue}$. You can review this Capex by clicking the following link: https://capex.heathus.com/?id=${CapexID}`,
                                                                    firstapprover,
                                                                    profile.name
                                                                )
                                                                isConfirmRequest(true)
                                                            }}
                                                           
                                                        >
                                                            Submit
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        <button 
                                                            className={`continueBtn animate__animated ${highlight? 'animate__wobble' : ''}` }
                                                            data-bs-target= "#custom-carousel"
                                                           
                                                            onClick={() => {
                                                               

                                                                if(currArr == 0 && validateFirstSlide()){
                                                                    isCurrArr(currArr + 1)
                                                                    $('#custom-carousel').carousel(1);
                                                                    isProgress(progress + 25)
                                                                   
                                                                }

                                                                else if(currArr == 1 && validateSecondSlide()){
                                                                    isCurrArr(currArr + 1)
                                                                    $('#custom-carousel').carousel(2);
                                                                    isProgress(progress + 25)
                                                                    
                                                                }

                                                                else if(currArr == 2 && validateThirdSlide()){
                                                                    isCurrArr(currArr + 1)
                                                                    $('#custom-carousel').carousel(3);
                                                                    isProgress(progress + 25)
                                                                    setTimeout(() => {isAnimate(true)}, 750)
                                                                    
                                                                }
                                                                else if(currArr == 3){
                                                                    isCurrArr(currArr + 1)
                                                                    $('#custom-carousel').carousel(4);
                                                                    isProgress(progress + 25)
                                                                    // setTimeout(() => {isAnimate(true)}, 750)
                                                                    
                                                                }
                                                                

                                                               
                                                                else{
                                                                    //console.log('da hellooooooooooo')
                                                                    isHighlight(true)
                                                                }
                                                                

                                                                
                                                            }}

                                                            // onMouseEnter={() => alert('hi')}
                                                            // onMouseLeave={() => isCheck(false)}
                                                        >
                                                            Continue
                                                        </button>
                                                    )
                                                }
                                                
                                            </div>
                                        </div>
                                
                                    </div>
                                    
                                    <br/>

                                    <div className="row h-100">
                                        <div className="col-12 h-100" style={{position:'relative'}}>
                                            <div id="custom-carousel" className="carousel slide"  style={{height:'90%'}}>
                                                <div className="carousel-inner h-100" style={{overflow:'visible'}}>
                                                    
                                                    <div className="carousel-item active">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="row blue-card g-3" >
                                                                    <div className="col-xl-6 col-sm-12">
                                                                        <div className="input-group mb-3">
                                                                            <span className="input-group-text" id="basic-addon1">Asset Category</span>
                                                                            <div className="form-floating">
                                                                                <select 
                                                                                    defaultValue='none'
                                                                                    // value={assetcategory}
                                                                                    className={`form-select animate__animated ${assetcategory == 'none' && highlight? 'need-validation animate__headShake' : ''}`} 
                                                                                    onChange={(e) => {
                                                                                        //console.log(e.target.selectedIndex)
                                                                                        var index = e.target.selectedIndex;
                                                                                        let text = e.target[index].text
                                                                                        //console.log(text)
                                                                                        isAssetCategory(e.target.value + ' / ' + text) 
                                                                                    }}
                                                                                >
                                                                                    <option selected value="none">None</option>
                                                                                    <option value="10500">Buildings</option>
                                                                                    <option value="10505">Leasehold Improvements</option>
                                                                                    <option value="10510">Land</option>
                                                                                    <option value="10520">Computer Equipment</option>
                                                                                    <option value="10525">Shop Equipment</option>
                                                                                    <option value="10530">Field Equipment</option>
                                                                                    <option value="10535">Demo Equipment</option>
                                                                                    <option value="10540">Furniture & Fixtures</option>
                                                                                    <option value="10546">Vehicles-Other</option>
                                                                                    <option value="10555">Rental Equipment</option>
                                                                                    <option value="10570">Software</option>
                                                                                </select>
                                                                                <label for="floatingSelect">Select an asset</label>
                                                                            </div>
                                                                        </div>

                                                                        <div className="input-group mb-3 flex-nowrap">
                                                                            <span className="input-group-text" >Quantity</span>
                                                                            
                                                                            <input 
                                                                                id="quantity"
                                                                                placeholder='Enter quantity...'
                                                                                className={`form-control animate__animated ${quantity.length < 1 && highlight? 'need-validation animate__headShake' : ''}`} 
                                                                                //eslint-disable-next-line
                                                                                value={quantity}
                                                                                // eslint-disable-next-line
                                                                                onChange={(e) => {
                                                                                    const input = e.target.value
                                                                                    //console.log('quantity val ==> ', input)

                                                                                    setTimeout(() => {
                                                                                        setQuantity(fixPrice(input)) 
                                                                                    }, 150);
                                                                                    

                                                                                    
                                                                                }}
                                                                                
                                                                            />
                                                                        </div>
                                                                        
                                                                        <div className="input-group mb-3">
                                                                            <span className="input-group-text " id="basic-addon1">Justification of Purchase</span>
                                                                            <input 
                                                                                type="text" 
                                                                                className={`form-control ${justificationOfPurchase.length < 1 && highlight? 'need-validation animate__animated animate__headShake' : ''}`}
                                                                                placeholder="Enter justification of purchase..."  
                                                                                value={justificationOfPurchase}
                                                                                onChange={(e) => {
                                                                                    const input = e.target.value
                                                                                    isJustificationOfPurchase(input)
                                                                                    
                                                                                }}
                                                                                required
                                                                            />
                                                                        </div>

                                                                        <div className="input-group mb-3">
                                                                            <span className="input-group-text" id="basic-addon1">Ship To Address</span>
                                                                            <input 
                                                                                type="text" 
                                                                                className={`form-control ${shiptoaddress.length < 1 && highlight? 'need-validation animate__animated animate__headShake' : ''}`}
                                                                                placeholder="123 Address St"
                                                                                required
                                                                                value={shiptoaddress}
                                                                                onChange={(e) => {
                                                                                    const input = e.target.value
                                                                                    isShipToAddress(input)
                                                                                    
                                                                                }}
                                                                                
                                                                            />
                                                                            
                                                                        </div>
                                                                        <div className="input-group mb-3">
                                                                            <span className="input-group-text" id="basic-addon1">City/State/Zip</span>
                                                                            <input 
                                                                                id="shiptocity" 
                                                                                type="text" 
                                                                                className={`form-control ${shiptocity.length < 1 && highlight? 'need-validation animate__animated animate__headShake' : ''}`}
                                                                                placeholder="City and State" 
                                                                                value={shiptocity}
                                                                                onChange={(e) => {
                                                                                    const input = e.target.value
                                                                                    isShipToCity(input)
                                                                                    
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    

                                                                    <div className="col-xl-6 col-sm-12">
                                                                        <div className="input-group mb-3">
                                                                            <span className="input-group-text" id="basic-addon1">CAPEX or PURCHASE REQ</span>
                                                                            <input disabled type="text" className="form-control" placeholder={CapexID} aria-label="Username" aria-describedby="basic-addon1"/>
                                                                        </div>

                                                                        <div class="form-check form-switch">
                                                                            <input 
                                                                                class="form-check-input" 
                                                                                type="checkbox" role="switch"
                                                                                checked={customitem} 
                                                                                onChange={() => {
                                                                                    isCustomItem(!customitem)
                                                                                    
                                                                                    if(customitem) isPartValue('')
                                                                                    else {
                                                                                        isPartValue('N/A')
                                                                                        isItemDescValue('')
                                                                                    }
                                                                                    //console.log('custom item value ==> ',customitem)
                                                                                }}
                                                                            />
                                                                            <label class="form-check-label" for="flexSwitchCheckDefault">Input custom item</label>
                                                                        </div>
                                                                            
                                                                        <div className={`input-group mb-3`} style={{position:'relative'}}>
                                                                            <span className="input-group-text" id="basic-addon1">Part Number</span>
                                                                            
                                                                            <input 
                                                                                type="text" 
                                                                                className={`form-control ${partValue.length < 1 && highlight? 'need-validation animate__animated animate__headShake' : ''}`}
                                                                                placeholder="Search by part number..." 
                                                                                disabled={customitem}
                                                                                onChange={(e) => {
                                                                                    let text = e.target.value
                                                                                    
                                                                                    if(text.length > 0){
                                                                                        isPartSearch(true)
                                                                                        setTimeout(() => {
                                                                                            searchBy('partsearch',text)
                                                                                        }, 500);
                                                                                    }
                                                                                    else isPartSearch(false)
                                                                                    
                                                                                    isPartValue(text)
                                                                                    
                                                                                }}
                                                                                value={partValue}
                                                                            />
                                                                            
                                                                            
                                                                            {partsearch &&
                                                                                <>
                                                                                    <div className="searchRes">
                                                                                        {
                                                                                            searchCard?.length > 0?
                                                                                            (searchCard)
                                                                                            :
                                                                                            (
                                                                                                <div className="d-flex justify-content-center">
                                                                                                    <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                                                        <span className="visually-hidden">Loading...</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                    <span 
                                                                                        className="material-symbols-outlined dismiss-btn" 
                                                                                        onClick={() => {
                                                                                            isPartSearch(false)
                                                                                            isPartValue('')
                                                                                            
                                                                                        }}>close</span>
                                                                                </>
                                                                                
                                                                            }
                                                                        </div>
                                                                        
                                                                        <div className="input-group mb-3">
                                                                            <span className="input-group-text" id="basic-addon1">Item Description</span>
                                                                            
                                                                            {customitem?
                                                                                (
                                                                                    <input 
                                                                                        type="text" 
                                                                                        className={`form-control ${itemdescValue.length < 1 && highlight? 'need-validation animate__animated animate__headShake' : ''}`} 
                                                                                        placeholder="Search by part description..." 
                                                                                        aria-label="Username" 
                                                                                        aria-describedby="basic-addon1"
                                                                                        onChange={(e) => {
                                                                                            let text = e.target.value
                                                                                    
                                                                                            isItemDescValue(text)
                                                                                            
                                                                                        }}
                                                                                        value={itemdescValue}
                                                                                    />
                                                                                )
                                                                                :
                                                                                (
                                                                                    <input 
                                                                                        type="text" 
                                                                                        className={`form-control ${itemdescValue.length < 1 && highlight? 'need-validation animate__animated animate__headShake' : ''}`} 
                                                                                        placeholder="Input custom item description..." 
                                                                                        aria-label="Username" 
                                                                                        aria-describedby="basic-addon1"
                                                                                        onChange={(e) => {
                                                                                            let text = e.target.value
                                                                                            
                                                                                            if(text.length > 0){
                                                                                                isItemDescSearch(true)
                                                                                                setTimeout(() => {
                                                                                                    searchBy('descsearch',text)
                                                                                                }, 500);
                                                                                            }
                                                                                            else isItemDescSearch(false)
                                                                                            
                                                                                            isItemDescValue(text)
                                                                                            
                                                                                        }}
                                                                                        value={itemdescValue}
                                                                                    />
                                                                                )
                                                                            }
                                                                            
                                                                            
                                                                            
                                                                            {itemdescsearch &&
                                                                                <>
                                                                                    
                                                                                    <div className="searchRes">
                                                                                        {searchCard?.length > 0?
                                                                                            (searchCard)
                                                                                            :
                                                                                            (
                                                                                                <div className="d-flex justify-content-center">
                                                                                                    <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                                                        <span className="visually-hidden">Loading...</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                    <span 
                                                                                        className="material-symbols-outlined dismiss-btn" 
                                                                                        onClick={() => {
                                                                                            isItemDescSearch(false)
                                                                                            isItemDescValue('')
                                                                                        }}
                                                                                    >close</span>
                                                                                </>
                                                                                
                                                                            }
                                                                        </div>

                                                                        <div className="input-group mb-3">
                                                                            <span className="input-group-text" id="basic-addon1">Project Location</span>
                                                                            
                                                                            <input 
                                                                               
                                                                                type="text" 
                                                                                className={`form-control ${shipfromaddress.length < 1 && highlight? 'need-validation animate__animated animate__headShake' : ''}`} 
                                                                                placeholder="Your project's location" 
                                                                                value={shipfromaddress}
                                                                                onChange={(e) => {
                                                                                    const input = e.target.value
                                                                                    isShipFromAddress(input)
                                                                                    
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        {/* <div className="input-group mb-3">
                                                                            <span className="input-group-text" id="basic-addon1">City/State/Zip</span>
                                                                            
                                                                            <input 
                                                                               
                                                                                type="text" 
                                                                                className={`form-control ${shipfromcity.length < 1 && highlight? 'need-validation animate__animated animate__headShake' : ''}`} 
                                                                                placeholder="Houston, Texas, 77000" 
                                                                                value={shipfromcity}
                                                                                onChange={(e) => {
                                                                                    const input = e.target.value
                                                                                    isShipFromCity(input)
                                                                                    
                                                                                }}
                                                                            />
                                                                       
                                                                        </div> */}
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>

                                                    <div className="carousel-item">
                                                       

                                                        <div className="row">

                                                            <div className="col-12">
                                                                <div className="blue-card row">

                                                                    <div className="col-xl-6 col-sm-12">
                                                                        
                                                                          
                                                                        <div className="input-group mb-3" style={{position:'relative'}}>
                                                                            
                                                                            
                                                                            <span className="input-group-text" id="basic-addon1">Department</span>
                                                                            
                                                                            <div className="form-floating">
                                                                                <select 
                                                                                    className={`form-select ${departmentValue == 'none' && highlight? 'need-validation animate__animated animate__headShake' : ''}`} 
                                                                                    defaultValue='none' 
                                                                                    onChange={(e) => {
                                                                                        
                                                                                        var index = e.target.selectedIndex;
                                                                                        let text = e.target[index].text
                                                                                        
                                                                                        getBudgetCodes(e.target.value.split('/')[0])
                                                                                        getJobCodes(e.target.value.split('/')[0])
                                                                                        isDepartmentValue(e.target.value)
                                                                                        
                                                                                        //console.log('selected department ==> ',e.target.value.split('/')[0])
                                                                                        getFirstApprover(e.target.value.split('/')[0])
                                                                                    }}
                                                                                >
                                                                                    <option selected value="none">None</option>
                                                                                    {departmentsCard}
                                                                                </select>
                                                                                <label for="departmentSelect">Select a department</label>

                                                                                
                                                                            </div>

                                                                            {departments.length < 1 &&
                                                                                <div className='d-flex refreshBtn'>
                                                                                    <span 
                                                                                        className='material-symbols-outlined'
                                                                                        style={{fontSize: 20}}
                                                                                        onClick={() => getDepartments()}
                                                                                        
                                                                                    >refresh</span>
                                                                                </div>
                                                                            }
                                                                        </div>  
                                                                           
                                                                            
                                                                       

                                                                        

                                                                        <div className="input-group mb-3" style={{position:'relative'}}>
                                                                            
                                                                            
                                                                            <span className="input-group-text" id="basic-addon1">Project Code</span>
                                                                            
                                                                            <div className="form-floating">
                                                                                <select 
                                                                                    className={`form-select ${departmentValue == 'none' && highlight? 'need-validation animate__animated animate__headShake' : ''}`} 
                                                                                    defaultValue='none' 
                                                                                    onChange={(e) => {
                                                                                        
                                                                                        var index = e.target.selectedIndex;
                                                                                        let text = e.target[index].text
                                                                                        
                                                
                                                                                        //console.log('selected budget code ==> ',e.target.value)
                                                                                        isJobCodeValue(e.target.value)
                                                                                    }}
                                                                                    disabled={departmentValue == 'none'? true : false}
                                                                                >
                                                                                    <option selected value="none">None</option>
                                                                                    {pCodeCard}
                                                                                </select>
                                                                                <label for="departmentSelect">Select a project code based on department</label>

                                                                                
                                                                            </div>

                                                                           
                                                                        </div>  

                                                                        <div className="input-group mb-3">
                                                                            <span className="input-group-text" id="basic-addon1">Budget Code</span>
                                                                            
                                                                            <div className="form-floating">
                                                                                <select 
                                                                                    // className={`form-select ${budgetcodeValue == 'none' < 1 && highlight? 'need-validation animate__animated animate__headShake' : ''}`} 
                                                                                    className={`form-select`}
                                                                                    defaultValue='none' 
                                                                                    onChange={(e) => {
                                                                                        let index = e.target.selectedIndex
                                                                                        let budget = e.target[index].getAttribute('budget')
                                                                                        //console.log('cuurent budget ==> ', budget)
                                                                                        isBudgetCodeValue(e.target.value)
                                                                                        isBudgetAmountValue(parseInt(budget))
                                                                                        isEstimatedValue('')
                                                                                        
                                                                                    }}
                                                                                    disabled={departmentValue == 'none'? true : false}
                                                                                >
                                                                                    <option selected value="none">None</option>
                                                                                    {budgetcodeCard}
                                                                                </select>
                                                                                <label for="departmentSelect">Select a budget</label>
                                                                            </div>
                                                          
                                                                        </div>

                                                                        <div className="input-group mb-3">
                                                                            <span className="input-group-text" id="basic-addon1">Budget Amount</span>
                                                                            <input 
                                                                                type="text" className="form-control" placeholder="..." 
                                                                                value={budgetcodeValue == 'none'? 0 : formatNumberWithCommasAndDots(budgetamountValue)} 
                                                                                disabled
                                                                                
                                                                            />
                                                                            <span className="input-group-text">$</span>
                                                                        </div>

                                                                        <div className="input-group mb-3" style={{position:'relative'}}>
                                                                            <span className="input-group-text" id="basic-addon1">Estimated Value</span>
                                                                            
                                                                            <input 
                                                                                type="text" 
                                                                                className={`form-control ${estimatedValue.length < 1 && highlight? 'need-validation animate__animated animate__headShake' : ''}`} 
                                                                                placeholder="Total estimated value of the request..." 
                                                                                aria-label="Username" 
                                                                                aria-describedby="basic-addon1"
                                                                                
                                                                                onChange= {(e) => {
                                                                                    const value = parseInt(e.target.value)
                                                                                    //console.log('current value for request ==> ',text)
                                                                                   
                                                                                    
                                                                                    if((budgetamountValue - estimatedValue) > 0){
                                                                                        isBudgeted('true')
                                                                                    }
                                                                                    else {
                                                                                        console.log('switch budgeted to no ==> ')
                                                                                        isBudgeted('false')
                                                                                    }
                                                                                    isEstimatedValue(value? parseInt(value) : 1)

                                                                                                       
                                                                                }}
                                                                                value={estimatedValue}
                                                                            />
                                                                            
                                                                            <span className="input-group-text">$</span>
                                                                        
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="col-xl-6 col-sm-12">
                                                                        <div className="input-group mb-3">
                                                                            <span className="input-group-text" id="basic-addon1">Budgeted</span>
                                                                            <div className="form-floating">
                                                                                <select 
                                                                                    className={`form-select ${budgeted == 'none' && highlight? 'need-validation animate__animated animate__headShake' : ''}`}
                                                                                    disabled={departmentValue == 'none'? true : false}
                                                                                    value={budgeted}
                                                                                    onChange={(e) => {
                                                                                    
                                                                                        isBudgeted(e.target.value)

                                                                                        // console.log('cuurent budget val ==> ',budgeted, 'supposed to be ===> ', e.target.value)
                                                                                        
                                                                                        
                                                                                    }}
                                                                                >
                                                                                    <option value="true">Yes</option>
                                                                                    <option value="false">No</option>
                                                                                </select>
                                                                                <label for="budgetSelect">Is this budgeted</label>
                                                                                
                                                                            </div>
                                                                        </div>


                                                                        <div className="input-group mb-3">
                                                                            <span className="input-group-text" id="basic-addon1">Unbudgeted ROI</span>
                                                                            <input 
                                                                                type="text" 
                                                                                className={`form-control ${unbudgeted.length < 1 && !budgeted && highlight || unbudgeted.length < 1 && (budgetamountValue - estimatedValue) > 0 && highlight? 'need-validation animate__animated animate__headShake' : ''}`}
                                                                                placeholder="...the ROI for this request" aria-label="Username" aria-describedby="basic-addon1"
                                                                                onChange={(e) => {
                                                                                    if(!budgeted || budgeted == 'false'){
                                                                                        isUnbudgeted(e.target.value)
                                                                                    }
                                                                                }}
                                                                                value={(budgetamountValue - estimatedValue) < 0? formatNumberWithCommasAndDots(Math.abs(budgetamountValue - estimatedValue)) : '' }
                                                                                disabled = {(budgetamountValue - estimatedValue) > 0 || budgeted == 'true'? true : false}
                                                                            />
                                                                        </div>
                                                                        
                                                                        <div class="form-check form-switch">
                                                                            <input 
                                                                                class="form-check-input" 
                                                                                type="checkbox" role="switch"
                                                                                checked={newvendor} 
                                                                                onChange={() => {
                                                                                    hasNewVendor(!newvendor)
                                                                                    isVendorValue('')
                                                                                  
                                                                                }}
                                                                            />
                                                                            <label class="form-check-label" for="flexSwitchCheckDefault">New vendor?</label>
                                                                        </div>
                                                                        <div className="input-group mb-3">
                                                                            <span className="input-group-text" id="basic-addon1">Vendor</span>
                                                                            
                                                                            {!newvendor?
                                                                                (
                                                                                    <input 
                                                                                        type="text" 
                                                                                        className={`form-control ${vendorValue.length < 1 && highlight? 'need-validation animate__animated animate__headShake' : ''}`}
                                                                                        placeholder="Search by vendor..." 
                                                                                        aria-label="Username" 
                                                                                        aria-describedby="basic-addon1"
                                                                                        onChange={(e) => {
                                                                                            let text = e.target.value
                                                                                            
                                                                                            if(text.length > 0){
                                                                                                isVendorSearch(true)
                                                                                                setTimeout(() => {
                                                                                                    getVendors(text)
                                                                                                }, 500);
                                                                                            }
                                                                                            else isVendorSearch(false)
                                                                                            
                                                                                            isVendorValue(text)
                                                                                            
                                                                                        }}
                                                                                        value={vendorValue}
                                                                                    />
                                                                                )
                                                                                :
                                                                                (
                                                                                    <input 
                                                                                        type="text" 
                                                                                        className={`form-control ${vendorValue.length < 1 && highlight? 'need-validation animate__animated animate__headShake' : ''}`}
                                                                                        placeholder="Input new vendor..." 
                                                                                        aria-label="Username" 
                                                                                        aria-describedby="basic-addon1"
                                                                                        onChange={(e) => {
                                                                                            let text = e.target.value
                                                                                            isVendorValue(text)
                                                                                            
                                                                                        }}
                                                                                        value={vendorValue}
                                                                                    />
                                                                                )
                                                                            }
                                                                            {/* <input 
                                                                                type="text" 
                                                                                className={`form-control ${vendorValue.length < 1 && highlight? 'need-validation animate__animated animate__headShake' : ''}`}
                                                                                placeholder="Search by vendor..." 
                                                                                aria-label="Username" 
                                                                                aria-describedby="basic-addon1"
                                                                                onChange={(e) => {
                                                                                    let text = e.target.value
                                                                                    
                                                                                    if(text.length > 0){
                                                                                        isVendorSearch(true)
                                                                                        setTimeout(() => {
                                                                                            getVendors(text)
                                                                                        }, 500);
                                                                                    }
                                                                                    else isVendorSearch(false)
                                                                                    
                                                                                    isVendorValue(text)
                                                                                    
                                                                                }}
                                                                                value={vendorValue}
                                                                            /> */}
                                                                            
                                                                            
                                                                            {vendorsearch &&
                                                                                <>
                                                                                    
                                                                                    <div className="searchRes">
                                                                                        {searchVendorCard?.length > 0?
                                                                                            (searchVendorCard)
                                                                                            :
                                                                                            (
                                                                                                <div className="d-flex justify-content-center">
                                                                                                    <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                                                        <span className="visually-hidden">Loading...</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                    <span 
                                                                                        className="material-symbols-outlined dismiss-btn" 
                                                                                        onClick={() => {
                                                                                            isVendorSearch(false)
                                                                                            isVendorValue('')
                                                                                        }}
                                                                                    >close</span>
                                                                                </>
                                                                                
                                                                            }
                                                                        </div>

                                                                        <div className="input-group mb-3">
                                                                            <span className="input-group-text" id="basic-addon1">Quote #</span>
                                                                            <input 
                                                                                type="text" 
                                                                                className={`form-control ${quoteValue.length < 1 && highlight? 'need-validation animate__animated animate__headShake' : ''}`}
                                                                                placeholder="Enter quote..."
                                                                                value={quoteValue}
                                                                                onChange={(e) => {
                                                                                    let text = e.target.value
                                                                                    isQuoteValue(text)
                                                                                    
                                                                                }}
                                                                            />
                                                                            {/* <input type="file" name="filename"/> */}
                                                                        </div>

                                                                        <div className="input-group mb-3">
                                                                            <span className="input-group-text" style={{border:`${base64String.length < 15 && highlight? '2px solid red' : ''}`}} >Attach Quote</span>
                                                
                                                                            <input 
                                                                                type="file" 
                                                                                accept=".pdf,.doc,.docx" required 
                                                                                name="filename" 
                                                                                onChange={handleFileChange}
                                                                            />
                                                                            {(base64String.length < 15 && highlight) &&
                                                                                <p style={{color:'red',marginTop:'0.5%'}}>Please attach a quote.</p>
                                                                            }
                                                                        </div>

                                                                        <div className="input-group mb-3">
                                                                            <span className="input-group-text" id="basic-addon1">Quote Date</span>
                                                                            
                                                                            <DatePicker 
                                                                                selected={startDateQuote} 
                                                                                onChange={(date) => {
                                                                                    setStartDateQuote(date)
                                                                                    
                                                                                }}
                                                                                minDate={today.substractDays(30)}
                                                                                maxDate={today}
                                                                            />
                                                                        </div>


                                                                        
                                                                    </div>
                                                                    

                                                                   
                                                                    
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                    
                                                    <div className="carousel-item">
                                                      
                                                        <div class="row">

                                                            <div class="col-12">
                                                                <div className="blue-card row" style={{marginBottom:'20%'}}>
                                                                    <div className="col-xl-6 col-sm-12">
                                                                        <div class="input-group mb-3">
                                                                            <span class="input-group-text" id="basic-addon1">Originator</span>
                                                                            <input 
                                                                                type="text" 
                                                                                className={`form-control ${originator.length < 1 && highlight? 'need-validation animate__animated animate__headShake' : ''}`}  
                                                                                placeholder="...John Doe" 
                                                                                value={originator}
                                                                                disabled
                                                                            />
                                                                        </div>


                                                                        <div class="input-group mb-3">
                                                                            <span class="input-group-text" id="basic-addon1" >Contact Phone Number</span>
                                                                            <input 
                                                                                type="text" 
                                                                                className={`form-control ${phone.length < 1 && highlight? 'need-validation animate__animated animate__headShake' : ''}`} 
                                                                                placeholder="...123-456-7890"
                                                                                value={phone}
                                                                                onChange={(e) => {
                                                                                    let input = e.target.value
                                                                                    if(input.length < 11) isPhone(input)

                                                                                }}
                                                                            />
                                                                        </div>


                                                                    
                                                                    </div>

                                                                    <div className="col-xl-6 col-sm-12">
                                                                    
                                                                        <div class="input-group mb-3">
                                                                            <span class="input-group-text" id="basic-addon1">Request Date</span>
                                                                            <input type="text" class="form-control" disabled aria-label="Username" aria-describedby="basic-addon1" value={new Date}/>
                                                                        </div>

                                                                        <div class="input-group mb-3">
                                                                            <span class="input-group-text" id="basic-addon1" >Date Needed</span>
                                                                            
                                                                            <DatePicker 
                                                                                selected={startDateNeed} 
                                                                                onChange={(date) => setStartDateNeeded(date) } 
                                                                                minDate={today}
                                                                                maxDate={today.addDays(30)}
                                                                                // popperPlacement="top-start"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    

                                                                
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>

                                                    <div className="carousel-item">
                                                        <div className="row">
                                                            <div className="col-3" style={{position:'relative'}}>
                                                                <Ring 
                                                                    Status ={approvalRequired() > 1 && animate ? 'pending' : ''}
                                                                    Text = 'Needs approval from Department'
                                                                />

                                                                <div className={`connectLine ${approvalRequired() > 1 && animate ? 'active' : ''}`} />
                                                            </div>

                                                            <div className="col-3" style={{position:'relative'}}>
                                                                <Ring 
                                                                    Status ={approvalRequired() >= 2 && animate ? 'pending' : ''}
                                                                    Text = {approvalRequired() >= 2? 'Needs approval from Financing' : ''}
                                                                />

                                                                <div className={`connectLine ${approvalRequired() > 2 && animate ? 'active' : ''}`} />
                                                            </div>

                                                            <div className="col-3" style={{position:'relative'}}>
                                                                <Ring 
                                                                    Status ={approvalRequired() >= 3 && animate ? 'pending' : ''}
                                                                    Text = {approvalRequired() >= 3? 'Needs approval from CEO' : ''}
                                                                />
                                                                <div className={`connectLine ${approvalRequired() > 3 && animate ? 'active' : ''}`} />
                                                            </div>

                                                            <div className="col-3" style={{position:'relative'}}>
                                                                <Ring 
                                                                    Status ={approvalRequired() == 4 && animate ? 'pending' : ''}
                                                                    Text = {approvalRequired() == 4? 'Needs approval from President' : ''}
                                                                />
                                                                
                                                            </div>
                                                        </div>
                                            
                                                    </div>

                                                    <div className="carousel-item">
                                                        {confirmRequest?
                                                            (
                                                                <Player
                                                                    autoplay
                                                                    onEvent={event => {
                                                                        if (event === 'complete'){
                                                                           
                                                                            setTimeout(() => {
                                                                                // eslint-disable-next-line
                                                                                $('#requestCard').modal('hide')
                                                                            }, 450);
                                                                            isConfirmRequest(false)
                                                                        }
                                                                    }}
                                                                    src={require('../../animations/greetcomplete.json')}
                                                                    style={{ height: '25%', width: '30%',justifyContent:'center', alignItems:'center' }}
                                                                />
                                                            
                                                            )
                                                            :
                                                            (
                                                                <div className="row blue-card">
                                                                    <div className="col-12">
                                                                        <p className="mb-0 text-center">Make sure all fields are filled correctly before submitting.</p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            

                                        </div>

                                        
                                    </div>       
                                </div>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>

            <div className="modal" id="detailsCard"  tabIndex="-1" data-bs-keyboard="false">
                <div className="modal-dialog modal-fullscreen animate__animated animate__zoomInUp animate__faster">
                    
                    <div className="row w-100 h-100" style={{height:'70%'}}>
                        <div className="col-12 center">
                            <div className='details-card'>

                                <span 
                                    className="material-symbols-outlined btn-close" 
                                    data-bs-dismiss="modal" aria-label="Close"
                                    style={{
                                        position:'absolute',
                                        right:'3%'
                                    }}
                                >close</span>

                               
                                <div className="w-100 h-100" style={{overflowY:'auto',overflowX:'hidden'}}>
                                   
                                    

                                    <div className="blue-card">
                                        <div className="row h-100">
                                            <div className="col-12 h-100" style={{position:'relative'}}>
                                                <div className="row">
                                                    <div className="col" style={{position:'relative'}}>
                                                        <Ring 
                                                            Status ={currRequest?.Approval1}
                                                            Text =  {currRequest?.Approval1 == 'pending'? 'Pending approval from Department' : `Request ${currRequest?.Approval1} by ${currRequest?.Approval1ByName} on ${new Date(currRequest?.Approval1Date).toString().split(' ').slice(0, 4).join(' ')}`} 
                                                        />

                                                        {currRequest?.Approval1 !== 'denied' && 
                                                            <div className={`connectLine ${currRequest?.ApprovalRequired >= 2 && animate ? 'active' : '' } ${currRequest?.Approval1 == 'approved'? 'approved' : ''}`} />
                                                        }
                                                    </div>
                                                  
                                                    {currRequest?.Approval1 !== 'denied' &&
                                                        <div className="col" style={{position:'relative'}}>
                                                            <Ring 
                                                                Status ={currRequest?.Approval2}
                                                                Text = {currRequest?.Approval2 == 'pending'? 'Pending approval from Accounting' : `Request ${currRequest?.Approval2} by ${currRequest?.Approval2ByName} on ${new Date(currRequest?.Approval2Date).toString().split(' ').slice(0, 4).join(' ')}`}
                                                            />

                                                            {(currRequest?.Approval2 !== 'denied' && currRequest?.Approval3) &&
                                                                <div className={`connectLine ${currRequest?.ApprovalRequired >= 2 && animate ? 'active' : '' } ${currRequest?.Approval2 == 'approved'? 'approved' : ''}`} />
                                                            }
                                                        </div>
                                                    } 

                                                    {currRequest?.ApprovalRequired >= 3 && currRequest?.Approval2 !== 'denied' && currRequest?.Approval1 !== 'denied' &&
                                                        <div className="col" style={{position:'relative'}}>
                                                            <Ring 
                                                                Status ={currRequest?.Approval3}
                                                                Text = {currRequest?.Approval3 == 'pending'? 'Pending approval from CEO' : `Request ${currRequest?.Approval3} by ${currRequest?.Approval3ByName} on ${new Date(currRequest?.Approval3Date).toString().split(' ').slice(0, 4).join(' ')}`}
                                                            />

                                                            {currRequest?.Approval3 !== 'denied' &&
                                                                <div className={`connectLine ${currRequest?.ApprovalRequired >=4 && animate ? 'active' : '' } ${currRequest?.Approval3 == 'approved'? 'approved' : ''}`} />
                                                            }
                                                        </div>
                                                    }

                                                    {currRequest?.ApprovalRequired >= 4 && currRequest?.Approval3 !== 'denied' && currRequest?.Approval2 !== 'denied' && currRequest?.Approval1 !== 'denied' &&
                                                        <div className="col" style={{position:'relative'}}>
                                                            <Ring 
                                                                Status ={currRequest?.Approval4}
                                                                Text = {currRequest?.Approval4 == 'pending'? 'Pending approval from President' : `Request ${currRequest?.Approval4} by ${currRequest?.Approval2ByName} on ${new Date(currRequest?.Approval4Date).toString().split(' ').slice(0, 4).join(' ')}`}
                                                            />
                                                            
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                        
                                        </div>
                                    </div>

                                    <br/><br/>

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row blue-card g-2 needs-validation testform" novalidate>
                                                <h5>Purchase</h5>
                                                <div className="col-xl-6 col-sm-12">
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">Asset Category</span>
                                                        <input className="form-control" value={currRequest?.AssetCategory} disabled  />
                                                    </div>

                                                    <div className="input-group mb-3 flex-nowrap">
                                                        <span className="input-group-text" id="basic-addon1">Quantity</span>
                                                        <input className="form-control" value={currRequest?.Quantity} disabled  />
                                                    </div>
                                                    
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text " id="basic-addon1">Justification of Purchase</span>
                                                        <textarea className="form-control" value={currRequest?.JustificationOfPurchase} disabled  />
                                                    </div>

                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">Ship To Address</span>
                                                        <textarea className="form-control" value={currRequest?.ShipToAddress} disabled  />
                                                    </div>
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">City/State/Zip</span>
                                                        <textarea className="form-control" value={currRequest?.AddressTo} disabled  />
                                                    </div>
                                                </div>
                                                

                                                <div className="col-xl-6 col-sm-12">
                                                    <div className="input-group mb-3">
                                                            <span className="input-group-text" id="basic-addon1">CAPEX or PURCHASE REQ</span>
                                                            <input className="form-control" value={currRequest?.ID} disabled  />
                                                        </div>
                                                        
                                                        <div className="input-group mb-3" style={{position:'relative'}}>
                                                            <span className="input-group-text" id="basic-addon1">Part Number</span>
                                                            <input className="form-control" value={currRequest?.PartNumber} disabled  />
                                                        </div>
                                                        
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text" id="basic-addon1">Item Description</span>
                                                            <textarea className="form-control" value={currRequest?.ItemDescription} disabled  />
                                                        </div>

                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text" id="basic-addon1">Location</span>
                                                            <textarea className="form-control" value={currRequest?.AddressFrom} disabled  />
                                                        </div>
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text" id="basic-addon1">City/State/Zip</span>
                                                            <textarea className="form-control" value={currRequest?.Location} disabled  />
                                                        </div>
                                                    </div>
                                                
                                            </div>
                                        </div>
                                    </div>

                                    <br/>
        
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="blue-card g-2 row">
                                                <h5>Cost</h5>
                                                <div className="col-xl-6 col-sm-12">
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">Vendor</span>
                                                        <input className="form-control" value={currRequest?.Vendor} disabled  />
                                                    </div>

                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">Quote #</span>
                                                        <input className="form-control" value={currRequest?.Quote} disabled  />
                                                    </div>

                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">Quote Attachment</span>

                                                        {downloadQuote?
                                                            (
                                                                <>
                                                                    <input className="form-control" disabled />
                                                                    <a className='quoteDownload' href={downloadQuote} download={currRequest?.ID + `_quote.${downloadFormat}`}>Download</ a>
                                                                </>
                                                                
                                                                    
                                                               
                                                            )

                                                            :

                                                            (
                                                                <input className="form-control" value='There is no attached quote' disabled  />
                                                            )
                                                        }
                                                        
                                                    </div>

                                                   

                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">Department</span>
                                                        <input className="form-control" value={currRequest?.Department} disabled  />
                                                    </div>


                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">Budgeted</span>
                                                        <input className="form-control" value={currRequest?.Budgeted == 'true'? 'Yes' : 'No'} disabled  />
                                                    </div>


                                                   
                                                </div>
                                                

                                                <div className="col-xl-6 col-sm-12">
                                                    
                                                        
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">Unbudgeted ROI</span>
                                                        <input className="form-control" value={currRequest?.Unbudgeted? currRequest?.Unbudgeted : 'N/A'} disabled  />
                                                    </div>

                                                    <div className="input-group mb-3" style={{position:'relative'}}>
                                                        <span className="input-group-text" id="basic-addon1">Estimated Value</span>
                                                        <input className="form-control" value={formatNumberWithCommasAndDots(currRequest?.EstimatedValue)} disabled  />
                                                        <span className="input-group-text">$</span>
                                                    
                                                    </div>
                                                    
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">Project Code</span>
                                                        <input className="form-control" value={currRequest?.ProjectCode} disabled  />
                                                    </div>

                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">Budget Code</span>
                                                        <input className="form-control" value={(currRequest?.BudgetCode == 'none' || currRequest?.BudgetCode == '')? 'N/A' : currRequest?.BudgetCode} disabled  />
                                                    </div>

                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">Budget Amount</span>
                                                        <input className="form-control" value={formatNumberWithCommasAndDots(currRequest?.BudgetAmount)} disabled  />
                                                        <span className="input-group-text">$</span>
                                                    </div>
                                                    
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                
                                    <br/> <br/>
        
                                    <div class="row">

                                        <div class="col-12">
                                            <div className="blue-card row g-2">
                                                <h5>Contact</h5>
                                                <div className="col-xl-6 col-sm-12">
                                                    <div class="input-group mb-3">
                                                        <span class="input-group-text" id="basic-addon1">Originator</span>
                                                        <input className="form-control" value={currRequest?.OriginatorName} disabled  />
                                                    </div>


                                                    <div class="input-group mb-3">
                                                        <span class="input-group-text" id="basic-addon1">Contact Phone Number</span>
                                                        <input 
                                                            className="form-control" 
                                                            value={currRequest?.Phone} 
                                                            disabled  />
                                                    </div>


                                                
                                                </div>

                                                <div className="col-xl-6 col-sm-12">
                                                
                                                    <div class="input-group mb-3">
                                                        <span class="input-group-text" id="basic-addon1">Request Date</span>
                                                        <input className="form-control" value={currRequest?.DateRequest} disabled  />
                                                    </div>

                                                    <div class="input-group mb-3">
                                                        <span class="input-group-text" id="basic-addon1">Date Needed</span>
                                                        <input className="form-control" value={currRequest?.DateNeeded} disabled  />
                                                    
                                                    </div>
                                                </div>
                                                

                                            
                                            </div>
                                        </div>


                                    </div>

                                    <br/> <br/>

                                 
                                </div>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>

            <div className="col-xl-8 col-lg-8 h-100 custom-col">
                <div className={`custom-card animate__animated animate__fadeIn`} style={{overflow:'hidden'}}>
                    <h1 className="mb-0" style={{marginTop:'5%',color:'#1565C0'}}>Hello, {profile?.name.split(' ')[0]}</h1>

                    <div className="row w-100 h-100">
                        <div className="d-flex col-12 center">
                            <div 
                                className="d-flex roundBtn" 
                                onClick={() => isRequestOngoing(true)} 
                                style={{visibility:props.Visible}}
                                data-bs-toggle="modal" data-bs-target="#requestCard"
                            >
                                <span className="material-symbols-outlined text-center">add</span>
                            </div>
                            <small className='getstartedBtn'>Get started</small>
                        </div>
                    </div>
                
                </div>
            </div>

            <div className="col-xl-4 col-lg-4 h-100 custom-col">
                <div className={`custom-card animate__animated animate__fadeIn`}>
                    <br/>
                    
                    <div className="row animate__animated animate__fadeIn lightContainer" >
                        <div className="col-12">
                            <h6 className="mb-3 mt-3">Complete</h6>
                            {loaded?
                                (
                                    <>
                                        {renderCardsApproved}
                                    </>
                                    
                                )
                                :
                                (
                                    <div class="d-flex justify-content-center" style={{marginBottom:'10%'}}>
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )
                            }
                            
                        </div>
                    </div>

                    <br/>

                    <div className="row lightContainer animate__animated animate__fadeIn">
                        <div className="col-12">
                            <h6 className="mb-3 mt-3">Ongoing</h6>
                            {loaded?
                                (
                                    <>
                                        {renderCardsOngoing}
                                    </>
                                    
                                )
                                :
                                (
                                    <div class="d-flex justify-content-center" style={{marginBottom:'10%'}}>
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                
                </div>
            </div>
       </div>
    )
   
}

export default Home